import React, { PureComponent } from 'react';
import Observer from 'react-intersection-observer';
import { Accordion, Row, Col, Card, Container } from 'react-bootstrap';
import { TimelineLite } from 'gsap';

import Plus from '../../../static/assets/Plus_white.svg';
import Minus from '../../../static/assets/minus_white.svg';

// import Surface from '../../../static/assets/surface.svg';
// import Statistics from '../../../static/assets/statistics.svg';
import LightBluekey from '../../../static/assets/light_blue_key.svg';
import './_client-engagement.scss';
import { animateAccordion, animateBlocks } from '../../animations/ExpectAsAnAssociate/index';

export default class ClientEngagement extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayIcon1: false,
      displayIcon2: false,
      displayIcon3: false,
    };
  }

  componentDidMount() {
    const tlBlocks = new TimelineLite({ force3D: !0, yoyo: true });
    animateBlocks(tlBlocks, '.animate-fade-up-blocks');
  }

  animateProblem = InView => {
    const tlProblem = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateAccordion(
        tlProblem,
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '.animate-problem-fade-up'
      );
    }
  };

  animateApproach = InView => {
    const tlApproach = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateAccordion(
        tlApproach,
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '.animate-approach-fade-up'
      );
    }
  };

  animateImpact = InView => {
    const tlImpact = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      animateAccordion(
        tlImpact,
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '.animate-impact-fade-up'
      );
    }
  };

  render() {
    const {
      data: { accordionOne, accordionTwo, accordionThree },
    } = this.props;
    const { displayIcon1, displayIcon2, displayIcon3 } = this.state;
    return (
      <section className="expect-associate">
        <Row className="justify-content-around pb-3 animate-fade-up-blocks">
          <Col xs={12} lg={9}>
            <Accordion>
              <Card className="mb-3">
                <Accordion.Toggle
                  as={Card.Header}
                  onClick={() => {
                    this.setState({
                      displayIcon1: !displayIcon1,
                      displayIcon2: false,
                      displayIcon3: false,
                    });
                  }}
                  eventKey={accordionOne.eventKey}
                  className={`p-lg-4 py-4 px-3 ${
                    displayIcon1 ? 'associate-card-header-true' : 'associate-card-header-false'
                  }`}
                >
                  <div
                    className="px-lg-3
                   d-flex justify-content-between"
                  >
                    <div className="accordion-wrapper d-flex flex-row align-items-center">
                      <img
                        src={accordionOne.logo.publicURL}
                        className="d-none d-lg-block"
                        alt=""
                        style={{ width: '64px', height: '64px' }}
                      />
                      <img
                        src={accordionOne.logo.publicURL}
                        className="d-lg-none"
                        alt=""
                        style={{ width: '50px', height: '50px' }}
                      />
                      <h5 className="text-bold mb-0 pl-lg-5 pl-3">{accordionOne.title}</h5>
                    </div>
                    {displayIcon1 === false ? (
                      <div className="d-flex flex-row align-items-center mr-lg-3">
                        <Plus style={{ width: '24px', height: '24px' }} className="plus" alt="" />
                      </div>
                    ) : null}
                    {displayIcon1 === true ? (
                      <div className="d-flex flex-row align-items-center mr-lg-3">
                        <Minus style={{ width: '24px', height: '24px' }} alt="" />
                      </div>
                    ) : null}
                    {/* <PlusHover style={{ width: '24px' }} alt="" /> */}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey={accordionOne.eventKey}
                  className="px-2 px-lg-4 bg-appcolor"
                >
                  <Card.Body className="py-0">
                    <Container>
                      <Observer onChange={this.animateProblem} triggerOnce>
                        <Row>
                          <Col lg={12}>
                            <div className="px-lg-5">
                              <div className="position-relative line line-horizontal">
                                <LightBluekey />
                              </div>
                              <div className="animate-problem-fade-up">
                                {accordionOne.description.map(details => (
                                  <p className="text-white">{details.text}</p>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Observer>
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="mb-3">
                <Accordion.Toggle
                  as={Card.Header}
                  onClick={() => {
                    this.setState({
                      displayIcon2: !displayIcon2,
                      displayIcon1: false,
                      displayIcon3: false,
                    });
                  }}
                  eventKey={accordionTwo.eventKey}
                  className={`p-lg-4 py-4 px-3 ${
                    displayIcon2 ? 'associate-card-header-true' : 'associate-card-header-false'
                  }`}
                >
                  <div className="px-lg-3 d-flex justify-content-between">
                    <div className="accordion-wrapper d-flex flex-row align-items-center">
                      <img
                        src={accordionTwo.logo.publicURL}
                        className="d-none d-lg-block"
                        alt=""
                        style={{ width: '64px', height: '64px' }}
                      />
                      <img
                        src={accordionTwo.logo.publicURL}
                        className="d-lg-none"
                        alt=""
                        style={{ width: '50px', height: '50px' }}
                      />
                      <h5 className="text-bold mb-0 pl-lg-5 pl-3">{accordionTwo.title}</h5>
                    </div>
                    {displayIcon2 === false ? (
                      <div className="d-flex flex-row align-items-center mr-lg-3">
                        <Plus style={{ width: '24px', height: '24px' }} className="plus" alt="" />
                      </div>
                    ) : null}
                    {displayIcon2 === true ? (
                      <div className="d-flex flex-row align-items-center mr-lg-3">
                        <Minus style={{ width: '24px', height: '24px' }} alt="" />
                      </div>
                    ) : null}
                    {/* <PlusHover style={{ width: '24px' }} alt="" /> */}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey={accordionTwo.eventKey}
                  className="px-2 px-lg-4 bg-appcolor"
                >
                  <Card.Body className="py-0">
                    <Container>
                      <Observer onChange={this.animateApproach} triggerOnce>
                        <Row>
                          <Col lg={12}>
                            <div className="px-lg-5">
                              <div className="position-relative line line-horizontal">
                                <LightBluekey />
                              </div>
                              <div className="animate-approach-fade-up">
                                {accordionTwo.description.map(details => (
                                  <p className="text-white">{details.text}</p>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Observer>
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  onClick={() => {
                    this.setState({
                      displayIcon3: !displayIcon3,
                      displayIcon1: false,
                      displayIcon2: false,
                    });
                  }}
                  eventKey={accordionThree.eventKey}
                  className={`p-lg-4 py-4 px-3 ${
                    displayIcon3 ? 'associate-card-header-true' : 'associate-card-header-false'
                  }`}
                >
                  <div className="px-lg-3 d-flex justify-content-between">
                    <div className="accordion-wrapper d-flex flex-row align-items-center">
                      <img
                        src={accordionThree.logo.publicURL}
                        className="d-none d-lg-block"
                        alt=""
                        style={{ width: '64px', height: '64px' }}
                      />
                      <img
                        src={accordionThree.logo.publicURL}
                        className="d-lg-none"
                        alt=""
                        style={{ width: '50px', height: '50px' }}
                      />
                      <h5 className="text-bold mb-0 pl-lg-5 pl-3">{accordionThree.title}</h5>
                    </div>
                    {displayIcon3 === false ? (
                      <div className="d-flex flex-row align-items-center mr-lg-3">
                        <Plus style={{ width: '24px', height: '24px' }} className="plus" alt="" />
                      </div>
                    ) : null}
                    {displayIcon3 === true ? (
                      <div className="d-flex flex-row align-items-center mr-lg-3">
                        <Minus style={{ width: '24px', height: '24px' }} alt="" />
                      </div>
                    ) : null}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse
                  eventKey={accordionThree.eventKey}
                  className="px-2 px-lg-4 bg-appcolor"
                >
                  <Card.Body className="py-0">
                    <Container>
                      <Observer onChange={this.animateImpact} triggerOnce>
                        <Row>
                          <Col lg={12}>
                            <div className="px-lg-5">
                              <div className="position-relative line line-horizontal">
                                <LightBluekey />
                              </div>
                              <div className="animate-impact-fade-up">
                                {accordionThree.description.map(details => (
                                  <p className="text-white">{details.text}</p>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Observer>
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>
      </section>
    );
  }
}
