import React, { PureComponent } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Observer from 'react-intersection-observer';
import lightBlueCircle from '../../../static/img/expect_as_an_associate/Example1-day.svg';
import yellowCircle from '../../../static/img/expect_as_an_associate/Example2-day.svg';
import blueCircle from '../../../static/img/expect_as_an_associate/Example3-day.svg';
import ReadMore from '../../../static/assets/Readmore.svg';
import * as globalAnimation from '../../animations/globalAnimation';

import './_daylife.scss';

export default class DayLife extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
  }

  animateMosaic = InView => {
    const tl1 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeLeft(tl1, '.animate-fade-left');
      globalAnimation.fadeUp(tl1, '.animate-fade-up');
      globalAnimation.fadeRight(tl1, '.animate-fade-right');
    }
  };

  animateExampleDays = InView => {
    const tl2 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeDown(tl2, '.animate-one');
      globalAnimation.fadeDown(tl2, '.animate-two');
      globalAnimation.fadeDown(tl2, '.animate-three');
    }
  };

  // animateClientKickOff = InView => {
  //   const tl2 = new TimelineLite({ force3D: !0, yoyo: true });
  //   if (InView) {
  //     globalAnimation.fadeUp(tl2, '.animate-client-kickoff');
  //   }
  // };

  animateTravelFrom = InView => {
    const tl3 = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tl3, '.animate-travel-from');
    }
  };

  animateLearnMore = InView => {
    const tlLearnMore = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tlLearnMore, '.learnMoreHeading');
    }
  };

  render() {
    const {
      data: {
        title,
        subtitle,
        cards,
        times,
        exampleOne,
        exampleTwo,
        exampleThree,
        dayOne,
        dayTwo,
        dayThree,
      },
    } = this.props;
    return (
      <section className="day-life">
        <Row className="bg-appcolor justify-content-center mb-5">
          <Col xs={12}>
            <div className="wrapper">
              <div className="page_heading_section text-center">
                <h3 className="page_heading text-uppercase d-lg-block d-none white title">
                  {title}
                </h3>
                <h4 className="text-uppercase page_heading_child text-white subtitle ">{title}</h4>
              </div>
            </div>
          </Col>

          <Col xs={12} lg={9}>
            <Observer onChange={this.animateMosaic} triggerOnce>
              <div className="mb-3 mb-lg-5">
                <div className="masonry_three">
                  {cards.map(card => (
                    <div className={`${card.class}`}>
                      {card.images.map(img => (
                        <div className="brick">
                          <Img fluid={img.image.childImageSharp.fluid} />
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            </Observer>
          </Col>
        </Row>
        <Container fluid className="wrapper pb-0">
          <Row>
            <Col xs={12} className="mb-5 text-center">
              <div className="animate-heading-tt">
                <h5 className="font-weight-bold">{subtitle}</h5>
              </div>
            </Col>
          </Row>
          <Observer onChange={this.animateExampleDays} triggerOnce>
            <Row className="d-lg-flex d-none my-5">
              <Col xs={{ span: 4, offset: 1 }} className="pl-1">
                <div className="animate-one d-flex">
                  <div>
                    <img src={lightBlueCircle} alt="" height="26px" width="26px" />
                  </div>
                  <div>
                    <h4 className="font-weight-bold text-center text-lightblue mx-3">
                      {exampleOne.text}
                    </h4>
                  </div>
                </div>
              </Col>
              <Col xs={4} className="pl-1">
                <div className="animate-two d-flex">
                  <div>
                    <img src={yellowCircle} alt="" height="26px" width="26px" />
                  </div>
                  <div>
                    <h4 className="font-weight-bold text-center text-orange mx-3">
                      {exampleTwo.text}
                    </h4>
                  </div>
                </div>
              </Col>
              <Col xs={3} className="pl-1">
                <div className="animate-three d-flex">
                  <div>
                    <img src={blueCircle} alt="" height="26px" width="26px" />
                  </div>
                  <div>
                    <h4 className="font-weight-bold text-center text-appcolor mx-3">
                      {exampleThree.text}
                    </h4>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="mb-3 mb-lg-5">
              <Col xs={12} className="d-lg-none d-flex justify-content-center my-3 animate-one">
                <div>
                  <img src={lightBlueCircle} alt="" height="26px" width="26px" />
                </div>
                <div>
                  <h4 className="font-weight-bold text-center text-lightblue mx-3">
                    {exampleOne.text}
                  </h4>
                </div>
              </Col>
              <Col
                xs={3}
                lg={1}
                className="text-right d-flex flex-column justify-content-between hours-col animate-travel-from animate-one"
              >
                {times.map(time => (
                  <h6>{time}</h6>
                ))}
              </Col>
              <Col xs={9} lg={4}>
                <div className="list-point animate-one">
                  {/* <Observer onChange={this.animateClientKickOff} triggerOnce> */}
                  <div className="timeline-item-lightblue hour-4 animate-client-kickoff">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayOne.cardOne.title}</h5>
                      </div>
                    </div>
                  </div>

                  <div className="timeline-item-lightblue hour-2 animate-client-kickoff">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayOne.cardTwo.title}</h5>
                      </div>
                    </div>
                  </div>

                  <div className="timeline-item-lightblue hour-4 animate-client-kickoff">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayOne.cardThree.title}</h5>
                      </div>
                    </div>
                  </div>
                  {/* </Observer> */}
                </div>
                <div className="timeline-item-dark hour-2">
                  <div className="block_light d-flex h-100">
                    <div className="align-self-center">
                      <h5>{dayOne.cardFour.title}</h5>
                      <p>{dayOne.cardFour.description}</p>
                    </div>
                  </div>
                </div>
                <div className="list-point">
                  <div className="timeline-item-lightblue hour-10">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayOne.cardFive.title}</h5>
                        <p className="text-appcolor mb-0">{dayOne.cardFive.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={12} className="d-lg-none d-flex justify-content-center my-4">
                <div>
                  <img src={yellowCircle} alt="" height="26px" width="26px" />
                </div>
                <div>
                  <h4 className="font-weight-bold text-center text-orange mx-3">
                    {exampleTwo.text}
                  </h4>
                </div>
              </Col>
              <Col
                xs={3}
                lg={1}
                className="text-right d-flex flex-column justify-content-between hours-col d-lg-none"
              >
                {times.map(time => (
                  <h6>{time}</h6>
                ))}
              </Col>
              <Col xs={9} lg={4} className="animate-one">
                {/* <Observer onChange={this.animateTravelFrom} triggerOnce> */}
                <div className="timeline-item-dark hour-3 animate-travel-from">
                  <div className="block_light d-flex h-100">
                    <div className="align-self-center">
                      <h5>{dayTwo.cardOne.title}</h5>
                      <p>{dayTwo.cardOne.description}</p>
                    </div>
                  </div>
                </div>
                {/* </Observer> */}
                <div className="list-point animate-travel-from">
                  <div className="timeline-item-orange hour-14">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayTwo.cardTwo.title}</h5>
                        <p className="text-appcolor">{dayTwo.cardTwo.description}</p>
                      </div>
                    </div>
                  </div>
                  <div className="timeline-item-orange hour-2">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayTwo.cardThree.title}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item-dark hour-3">
                  <div className="block_light d-flex h-100">
                    <div className="align-self-center">
                      <h5>{dayTwo.cardFour.title}</h5>
                      <p>{dayTwo.cardFour.description}</p>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={12} className="d-lg-none d-flex justify-content-center my-4">
                <div>
                  <img src={blueCircle} alt="" height="26px" width="26px" />
                </div>
                <div>
                  <h4 className="font-weight-bold text-center text-appcolor mx-3">
                    {exampleThree.text}
                  </h4>
                </div>
              </Col>
              <Col
                xs={3}
                lg={1}
                className="text-right d-flex flex-column justify-content-between hours-col d-lg-none"
              >
                {times.map(time => (
                  <h6>{time}</h6>
                ))}
              </Col>
              <Col xs={9} lg={3} className="animate-one">
                <div className="list-point animate-travel-from">
                  <div className="timeline-item-blue hour-10">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayThree.cardOne.title}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item-dark hour-2">
                  <div className="block_light d-flex h-100">
                    <div className="align-self-center">
                      <h5>{dayThree.cardTwo.title}</h5>
                      <p>{dayThree.cardTwo.description}</p>
                    </div>
                  </div>
                </div>

                <div className="list-point">
                  <div className="timeline-item-blue hour-2">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayThree.cardThree.title}</h5>
                      </div>
                    </div>
                  </div>
                  <div className="timeline-item-blue hour-6">
                    <div className="block_light d-flex h-100">
                      <div className="align-self-center">
                        <h5 className="text-appcolor">{dayThree.cardFour.title}</h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="timeline-item-dark hour-2">
                  <div className="block_light d-flex h-100">
                    <div className="align-self-center">
                      <h5>{dayThree.cardFive.title}</h5>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Observer>
          <Row>
            <Col>
              <Observer onChange={this.animateLearnMore} triggerOnce>
                <div className="page_heading_section text-center learnMoreHeading">
                  <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-5">
                    <div>
                      <h5 className="text-appcolor text-bold mb-lg-0">What does BluePrint do?</h5>
                    </div>
                    <div className="d-flex justify-content-center pl-lg-4">
                      <div className="">
                        <Link
                          to="/what-does-blueprint-do/"
                          className=" learn_more bordered-btn text-center"
                        >
                          Learn more{' '}
                          <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Observer>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
