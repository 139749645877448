import React, { PureComponent } from 'react';
import Lottie from 'react-lottie';
import Observer from 'react-intersection-observer';
import { Link } from 'gatsby';
import { Accordion, Row, Col, Card, Container } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import Img from 'gatsby-image';
import ReadMore from '../../../static/assets/Readmore.svg';
import Plus from '../../../static/assets/Plus_white.svg';
import Minus from '../../../static/assets/minus_white.svg';
import Yellowkey from '../../../static/assets/yellow_key.svg';
import './_roleandimpact.scss';
import * as globalAnimation from '../../animations/globalAnimation';
import animationData from '../../animations/impact_animation.json';

export default class RoleAndImpact extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      displayIcon1: false,
      displayIcon2: false,
      displayIcon3: false,
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateImageXRight(this.tl, '.svg-line__line_yellow', '.svg_circle_yellow');
    globalAnimation.fadeUp(this.tl, '.animate-title-up');
    globalAnimation.animateImageToLeft(this.tl, '.animate-role-img', '.animate-bg');
  }

  animateClientEngagement = InView => {
    const tlClientEngagement = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tlClientEngagement, '.animate-client-cards');
    }
  };

  animateApproach = InView => {
    const tlApproac = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tlApproac, '.animate-approach');
    }
  };

  animateImpact = InView => {
    const tlImpact = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tlImpact, '.animate-impact-fade-up');
      globalAnimation.animateOnlyImageRight(
        tlImpact,
        '.animate-image-impact',
        '.animate-bg-impact'
      );
      globalAnimation.animateOnlyImageLeft(
        tlImpact,
        '.animate-image-imapact-left',
        '.animate-bg-impact-left'
      );
    }
  };

  animateLearnMore = InView => {
    const tlLearnMore = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tlLearnMore, '.learnMoreHeading');
    }
  };

  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
      },
    };

    const {
      RoleImpact: { description, image, accordionOne, accordionTwo, accordionThree },
    } = this.props;

    const { displayIcon1, displayIcon2, displayIcon3 } = this.state;
    return (
      <section className="expect-associate">
        <Row className="bg-grey">
          <Col xs={12} className="d-lg-none">
            <div className="wrapper pb-0 pr-lg-0">
              <div className="position-relative line line-horizontal py-3 py-lg-5 pl-lg-5">
                <Yellowkey />
                <div className="animate-title-up">
                  <h4 className="text-appcolor pb-lg-5">{description}</h4>
                </div>
              </div>
            </div>
            <div className="w-100">
              <Img
                className="mx-auto img-fluid cover_pic square"
                fluid={image.childImageSharp.fluid}
              />
            </div>
          </Col>
          <Col xs={12} md={10} lg={5} className="d-none d-lg-block">
            <div className="wrapper pb-0 pr-lg-0">
              <div className="position-relative line line-horizontal py-3 py-lg-5">
                <Yellowkey />
                <div className="animate-title-up">
                  <h5 className="text-appcolor pb-5">{description}</h5>
                </div>
              </div>

              <div className="">
                <div className="bg-appcolor-light animate-bg square my-lg-4 my-lg-0">
                  <div>
                    <Img
                      className="img-fluid cover_pic square animate-role-img"
                      fluid={image.childImageSharp.fluid}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={{ span: 5, offset: 1 }} className="d-none d-lg-block">
            <Lottie options={defaultOptions} height={720} width={400} isClickToPauseDisabled />
          </Col>
          <Col xs={12} className="d-lg-none d-block">
            <Lottie options={defaultOptions} height={450} width={250} isClickToPauseDisabled />
          </Col>
        </Row>

        <Row noGutters className="justify-content-around py-5">
          <Col xs={12} lg={9}>
            <Accordion>
              <Card className="mb-3">
                <Accordion.Toggle
                  as={Card.Header}
                  onClick={() => {
                    this.setState({
                      displayIcon1: !displayIcon1,
                      displayIcon2: false,
                      displayIcon3: false,
                    });
                  }}
                  eventKey="0"
                  className={`p-lg-4 ${
                    displayIcon1 ? 'associate-card-header-true' : 'associate-card-header-false'
                  }`}
                >
                  <div className="px-lg-3 d-flex justify-content-between">
                    <div className=" accordion-wrapper d-flex flex-row justify-content-center align-items-center">
                      <img
                        src={accordionOne.logo.publicURL}
                        alt=""
                        style={{ width: '64px', height: '64px' }}
                      />
                      <div className="pl-lg-5 pl-2">
                        <h4 className="mb-1">{accordionOne.title}</h4>
                        <p className="m-0">{accordionOne.subtitle}</p>
                      </div>
                    </div>
                    {displayIcon1 === false ? (
                      <div className="d-flex flex-row align-items-center mr-lg-3">
                        <Plus style={{ width: '24px', height: '24px' }} className="plus" alt="" />
                      </div>
                    ) : null}
                    {displayIcon1 === true ? (
                      <div className="d-flex flex-row align-items-center mr-lg-3">
                        <Minus style={{ width: '24px', height: '24px' }} alt="" />
                      </div>
                    ) : null}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="bg-appcolor">
                  <Card.Body className="">
                    <Container>
                      <Observer onChange={this.animateClientEngagement} triggerOnce>
                        <Row className="problem-cards px-2 animate-client-cards">
                          {accordionOne.cards.map((card, index) => (
                            <Col xs={12} lg={12} className="px-lg-5 my-1" key={String(index)}>
                              <div className="px-lg-5">
                                <div className="circle-light-bullets-small">
                                  <h5 className="text-lightblue">{card.title}</h5>
                                  <ul style={{ paddingLeft: '20px' }}>
                                    {card.list.map(lists => (
                                      <li key={lists.id} className="text-white">
                                        {lists.text}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Observer>
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="mb-3">
                <Accordion.Toggle
                  as={Card.Header}
                  onClick={() => {
                    this.setState({
                      displayIcon2: !displayIcon2,
                      displayIcon1: false,
                      displayIcon3: false,
                    });
                  }}
                  eventKey="1"
                  className={`p-lg-4 ${
                    displayIcon2 ? 'associate-card-header-true' : 'associate-card-header-false'
                  }`}
                >
                  <div className="px-lg-3 d-flex justify-content-between">
                    <div className="accordion-wrapper d-flex flex-row align-items-center">
                      <img
                        src={accordionTwo.logo.publicURL}
                        alt=""
                        style={{ width: '64px', height: '64px' }}
                      />
                      <div className="pl-lg-5 pl-2">
                        <h4 className="mb-1">{accordionTwo.title}</h4>
                        <p className="m-0">{accordionTwo.subtitle}</p>
                      </div>
                    </div>
                    {displayIcon2 === false ? (
                      <div className="d-flex flex-row align-items-center mr-lg-4">
                        <Plus style={{ width: '24px', height: '24px' }} className="plus" alt="" />
                      </div>
                    ) : null}
                    {displayIcon2 === true ? (
                      <div className="d-flex flex-row align-items-center mr-lg-4">
                        <Minus style={{ width: '24px', height: '24px' }} alt="" />
                      </div>
                    ) : null}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1" className="px-2 px-lg-4 bg-appcolor">
                  <Card.Body className="">
                    <Container>
                      <Observer onChange={this.animateApproach} triggerOnce>
                        <Row className="">
                          {accordionTwo.description.map((detail, index) => (
                            <Col xs={12} lg={6} className="mb-lg-3" key={String(index)}>
                              <div className="px-lg-5 animate-approach">
                                <h5 className="pb-2 text-lightblue">{detail.title}</h5>
                                <p className="text-white">{detail.information}</p>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </Observer>
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  onClick={() => {
                    this.setState({
                      displayIcon3: !displayIcon3,
                      displayIcon1: false,
                      displayIcon2: false,
                    });
                  }}
                  eventKey="2"
                  className={`p-lg-4 ${
                    displayIcon3 ? 'associate-card-header-true' : 'associate-card-header-false'
                  }`}
                >
                  <div className="px-lg-3 d-flex justify-content-between">
                    <div className="accordion-wrapper d-flex flex-row align-items-center">
                      <img
                        src={accordionThree.logo.publicURL}
                        alt=""
                        style={{ width: '64px', height: '64px' }}
                      />
                      <div className="pl-lg-5 pl-2">
                        <h4 className="mb-1">{accordionThree.title}</h4>
                        <p className="m-0">{accordionThree.subtitle}</p>
                      </div>
                    </div>
                    {displayIcon3 === false ? (
                      <div className="d-flex flex-row align-items-center mr-lg-4">
                        <Plus style={{ width: '24px', height: '24px' }} className="plus" alt="" />
                      </div>
                    ) : null}
                    {displayIcon3 === true ? (
                      <div className="d-flex flex-row align-items-center mr-lg-4">
                        <Minus style={{ width: '24px', height: '24px' }} alt="" />
                      </div>
                    ) : null}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2" className=" bg-appcolor">
                  <Card.Body className="px-0 pt-lg-5">
                    <Container fluid>
                      <Observer onChange={this.animateImpact} triggerOnce>
                        <Row noGutters className="bg-appcolor mb-lg-5">
                          <Col xs={12} className="d-lg-none my-3">
                            <div>
                              <Img
                                className="img-fluid cover_pic square"
                                fluid={accordionThree.sectionOne.image.childImageSharp.fluid}
                              />
                            </div>
                          </Col>
                          <Col xs={12} lg={4} className="d-none d-lg-block">
                            <div className="bg-orange">
                              <div className="animate-bg-imapact-left">
                                <Img
                                  className="img-fluid cover_pic square animate-image-imapact-left"
                                  fluid={accordionThree.sectionOne.image.childImageSharp.fluid}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} lg={{ span: 6, offset: 1 }}>
                            {accordionThree.sectionOne.description.map((detail, index) => (
                              <div
                                className="wrapper pb-3 py-0 pr-lg-5 animate-impact-fade-up"
                                key={String(index)}
                              >
                                <h5 id="title-engagement" className="text-lightblue pb-2">
                                  {detail.title}
                                </h5>
                                <p id="desc_one" className="text-white">
                                  {detail.information}
                                </p>
                              </div>
                            ))}
                          </Col>
                        </Row>
                      </Observer>
                    </Container>
                    <Container fluid>
                      <Row noGutters className="bg-appcolor mt-lg-5 pt-lg-5">
                        <Col xs={12} lg={{ offset: 1, span: 4 }} className="d-lg-none">
                          <div className="my-3">
                            <Img
                              className="img-fluid cover_pic square"
                              fluid={accordionThree.sectionTwo.image.childImageSharp.fluid}
                            />
                          </div>
                        </Col>
                        <Col xs={12} lg={6}>
                          {accordionThree.sectionTwo.description.map((detail, index) => (
                            <div
                              className="wrapper py-2 pr-lg-5 animate-impact-fade-up"
                              key={String(index)}
                            >
                              <h5 id="title-engagement" className="text-lightblue pb-2">
                                {detail.title}
                              </h5>
                              <p id="desc_one" className="text-white">
                                {detail.information}
                              </p>
                            </div>
                          ))}
                        </Col>
                        <Col xs={12} lg={{ offset: 2, span: 4 }}>
                          <div className="bg-appcolor-light d-none d-lg-block">
                            <div className="animate-bg-impact">
                              <Img
                                className="img-fluid cover_pic square animate-image-impact"
                                fluid={accordionThree.sectionTwo.image.childImageSharp.fluid}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col>
            <Observer onChange={this.animateLearnMore} triggerOnce>
              <div className="page_heading_section text-center learnMoreHeading">
                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center mb-5">
                  <div>
                    <h5 className="text-appcolor text-bold mb-lg-0">What does BluePrint do?</h5>
                  </div>
                  <div className="d-flex justify-content-center pl-lg-4">
                    <div className="">
                      <Link
                        to="/what-does-blueprint-do/"
                        className=" learn_more bordered-btn text-center"
                      >
                        Learn more{' '}
                        <ReadMore className="arrow-translate mb-0" height="10px" width="40px" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Observer>
          </Col>
        </Row>
      </section>
    );
  }
}
