import React, { PureComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { TimelineLite } from 'gsap';
import { graphql } from 'gatsby';

import Img from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import '../sass/_expect-as-an-associate.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DayLife from '../components/ExpectAsAnAssociation/DayLife';
import ClientEngagement from '../components/ExpectAsAnAssociation/ClientEngagement';
import RoleAndImpact from '../components/ExpectAsAnAssociation/RoleAndImpact';
import * as globalAnimation from '../animations/globalAnimation';

export default class ClientTestimonials extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 'ClientEngagement',
      activeCard: 0,
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    globalAnimation.animateHeading(this.tl, '.title', '.subtitle');
    globalAnimation.fadeUp(this.tl, '.associate-cards');
  }

  render() {
    const { activePage, activeCard } = this.state;
    const {
      data: {
        markdownRemark: {
          frontmatter: { title, cards, ClientEngagementExample, RoleImpact, dayLife },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor="appcolor">
        <SEO pageTitle={title} />

        <Container fluid>
          <Row>
            <Col xs={12} lg={12}>
              <div className="main_wrapper">
                <div className="wrapper px-0">
                  <div className="page_heading_section text-center">
                    <h3 className="page_heading text-uppercase d-lg-block d-none title">{title}</h3>
                    <h4 className="text-uppercase page_heading_child subtitle">{title}</h4>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center associate-cards">
            {cards.map((card, index) => (
              <Col xs={10} lg={3} key={String(index)}>
                <div className="brick position-relative mb-4">
                  <Img fluid={card.image.childImageSharp.fluid} />
                  <a
                    onClick={() => {
                      this.setState({
                        activePage: card.page,
                        activeCard: index,
                      });
                    }}
                  >
                    <div className="front_text d-flex p-4">
                      <h4 className="text-white align-self-end">{card.title}</h4>
                    </div>
                    <div
                      className={`${
                        activeCard === index ? 'active-description_block' : 'description_block'
                      } p-4`}
                    >
                      <h4 className="text-white align-self-end">{card.title}</h4>
                    </div>
                  </a>
                </div>
                {activeCard === index ? (
                  <div className="blue_line mb-3 mb-lg-4">
                    <hr />
                  </div>
                ) : null}
              </Col>
            ))}
          </Row>
          {activePage === 'RoleImpact' ? (
            <RoleAndImpact data={RoleImpact} RoleImpact={RoleImpact} />
          ) : null}
          {activePage === 'DayLife' ? <DayLife data={dayLife} /> : null}
          {activePage === 'ClientEngagement' ? (
            <ClientEngagement data={ClientEngagementExample} />
          ) : null}
        </Container>
      </Layout>
    );
  }
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "what-can-i-expect-as-an-associate" } }) {
      frontmatter {
        title
        ClientEngagementExample {
          accordionOne {
            title
            eventKey
            logo {
              publicURL
            }
            description {
              text
            }
          }
          accordionTwo {
            title
            eventKey
            logo {
              publicURL
            }
            description {
              text
            }
          }
          accordionThree {
            title
            eventKey
            logo {
              publicURL
            }
            description {
              text
            }
          }
        }
        RoleImpact {
          description
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          bpsvg {
            image {
              publicURL
            }
          }
          accordionOne {
            title
            subtitle
            logo {
              publicURL
            }
            cards {
              title
              list {
                id
                text
              }
            }
          }
          accordionTwo {
            title
            subtitle
            logo {
              publicURL
            }
            description {
              title
              information
            }
          }
          accordionThree {
            title
            subtitle
            logo {
              publicURL
            }
            sectionOne {
              image {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              description {
                title
                information
              }
            }
            sectionTwo {
              image {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              description {
                title
                information
              }
            }
          }
        }
        cards {
          title
          page
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        dayLife {
          title
          subtitle
          cards {
            class
            images {
              image {
                childImageSharp {
                  fluid(maxWidth: 512, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              id
            }
          }
          exampleOne {
            text
          }
          exampleTwo {
            text
          }
          exampleThree {
            text
          }
          times
          dayOne {
            cardOne {
              title
            }
            cardTwo {
              title
            }
            cardThree {
              title
            }
            cardFour {
              title
              description
            }
            cardFive {
              title
              description
            }
          }
          dayTwo {
            cardOne {
              title
              description
            }
            cardTwo {
              title
              description
            }
            cardThree {
              title
            }
            cardFour {
              title
              description
            }
          }
          dayThree {
            cardOne {
              title
            }
            cardTwo {
              title
              description
            }
            cardThree {
              title
            }
            cardFour {
              title
            }
            cardFive {
              title
            }
          }
        }
      }
    }
  }
`;
